import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// @mui material components
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDCircularLoader from "components/MDCircularLoader";
// NewProduct page components
import AddPress from "../AddPress";
import Media from "../Media";
import PlanNPayment from "../PlanNPayment";
import ReviewNPublish from "../ReviewNPublish";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  GET_ARTICLE_BY_ID,
  IGetArticleByIdVars,
  IGetArticleById,
} from "graphql/queries/getArticleById";
import MDTypography from "components/MDTypography";
import { GET_USER_WALLET, IGetUserWallet } from "graphql/queries/getUserWallet";
import {
  UPDATE_ARTICLE_STATUS,
  IUpdateArticleStatusVars,
} from "graphql/mutations/updateArticleStatus";
interface Props {
  onClose?: () => void;
  idVal?: any;
  refetch?: any;
}

function getSteps(): string[] {
  return ["Add Press Release", "Category & Media", "Plan & Payment", "Review & Publish"];
}

function getStepContent(
  stepIndex: number,
  saveData: boolean,
  handleNext: any,
  article: any,
  fetchData: any,
  setSaveData: any,
  setPayable: any
): JSX.Element {
  switch (stepIndex) {
    case 0:
      return (
        <AddPress
          cb={handleNext}
          saveData={saveData}
          data={article}
          refetch={fetchData}
          setSaveData={setSaveData}
          disabled={article?.status === "PUBLISHED"}
        />
      );
    case 1:
      return (
        <Media
          cb={handleNext}
          saveData={saveData}
          data={article}
          disabled={article?.status === "PUBLISHED"}
          refetch={fetchData}
          setSaveData={setSaveData}
        />
      );
    case 2:
      return (
        <PlanNPayment
          cb={handleNext}
          saveData={saveData}
          data={article}
          refetch={fetchData}
          setSaveData={setSaveData}
          setPayable={setPayable}
          disabled={article?.status !== "DRAFT"}
        />
      );
    case 3:
      return <ReviewNPublish id={article?.id} />;
    default:
      return null;
  }
}

function ReleaseForm({ idVal, onClose, refetch }: Props): JSX.Element {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [saveData, setSaveData] = useState<boolean>(false);
  const [articleId, setId] = useState<string>(idVal && idVal !== true && idVal);
  const [article, setArticle] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [payable, setPayable] = useState<number>(0);
  const steps = getSteps();
  const isLastStep: boolean = activeStep === steps.length - 1;

  const handleNext = (id?: any) => {
    if (id) setId(id);
    setSaveData(false);
    setActiveStep(activeStep + 1);
  };
  const handleBack = () => {
    if (activeStep === 0) {
      onClose();
    } else {
      if (saveData) setSaveData(false);
      setActiveStep(activeStep - 1);
    }
  };
  const [updateArticleStatus] = useMutation<IUpdateArticleStatusVars>(UPDATE_ARTICLE_STATUS);
  const [getArticleById] = useLazyQuery<IGetArticleById, IGetArticleByIdVars>(GET_ARTICLE_BY_ID, {
    fetchPolicy: "network-only",
  });
  const { data: userWallet } = useQuery<IGetUserWallet>(GET_USER_WALLET, {
    fetchPolicy: "network-only",
  });
  const fetchData = () => {
    setLoading(true);
    getArticleById({
      variables: {
        id: articleId,
      },
    })
      .then((res: any) => {
        setArticle(res?.data?.getArticleById);
        setLoading(false);
      })
      .catch((err) => {
        console.error("error in detail fetching", err);
        setLoading(false);
      });
  };
  useEffect(() => {
    if (articleId) {
      fetchData();
    }
  }, [articleId]);
  const handleSend = () => {
    updateArticleStatus({
      variables: {
        params: {
          id: article?.id,
          status: "SUBMITTED",
        },
      },
    })
      .then(() => {
        toast.success("Submitted successfully");
        refetch();
        onClose();
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };
  const handleAddCredits = () => {
    navigate("/my-credits");
  };
  return (
    <>
      <MDBox mt={-3} mb={3} mx={2}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{
            background: "#5F6BE3",
          }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </MDBox>
      <MDBox p={2}>
        <MDBox>
          {loading ? (
            <MDCircularLoader startLoader />
          ) : (
            <>
              {getStepContent(
                activeStep,
                saveData,
                handleNext,
                article,
                fetchData,
                setSaveData,
                setPayable
              )}
            </>
          )}
        </MDBox>
      </MDBox>
      <MDBox
        width="102.47%"
        display="flex"
        justifyContent="space-between"
        bgColor="white"
        alignItems="center"
        sx={{
          position: "sticky",
          bottom: 0,
          marginLeft: "-1.2%",
          padding: "2rem 1rem",
          boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
          zIndex: 1000,
        }}
      >
        <MDButton
          sx={{
            height: "40px !important",
            boxShadow:
              "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px !important",
          }}
          size="small"
          variant="outlined"
          color="light"
          onClick={handleBack}
        >
          <MDTypography fontWeight="regular" fontSize="16px">
            {"Back"}
          </MDTypography>
        </MDButton>
        <MDBox display="flex" alignItems="center">
          {[2, 3].includes(activeStep) && article?.status === "DRAFT" && (
            <MDBox mr={2}>
              <MDTypography
                fontWeight="regular"
                fontFamily="Roboto"
                fontSize="15px"
                sx={{ color: "#7B809A" }}
              >
                Credits Payable
              </MDTypography>
              <MDTypography
                textAlign="right"
                fontWeight="bold"
                fontSize="18px"
                sx={{ color: "#F3BE00" }}
              >
                {payable}
              </MDTypography>
            </MDBox>
          )}
          <MDButton
            color="primary"
            onClick={
              !isLastStep
                ? () => setSaveData(true)
                : article?.totalCredits <= userWallet?.getUserWallet?.balance ||
                  article?.status === "AWAITING_APPROVAL"
                ? handleSend
                : handleAddCredits
            }
            sx={{
              visibility: isLastStep && article?.status === "PUBLISHED" && "collapse",
            }}
          >
            {isLastStep
              ? article?.totalCredits <= userWallet?.getUserWallet?.balance ||
                article?.status === "AWAITING_APPROVAL"
                ? article?.status === "DRAFT"
                  ? "Submit"
                  : "Resubmit"
                : "Add Credits"
              : "next"}
          </MDButton>
        </MDBox>
      </MDBox>
    </>
  );
}

export default ReleaseForm;
